tui-accordion-item[finAccordionItem],
tui-accordion-item[fin-accordion-item],
fin-widget-wrapper // TODO: to support backward compatibility
{
  --fin-accordion-item-dark-header-bg-color: var(--fin-color-primary-dark-500);
  --fin-accordion-item-dark-header-hover-bg-color: var(--fin-color-primary-dark-400);
  --fin-accordion-item-dark-header-active-bg-color: var(--fin-color-primary-dark-700);
  --fin-accordion-item-dark-header-focus-bg-color: var(--fin-color-primary-dark-500);
  --fin-accordion-item-dark-header-focus-border-color: var(--fin-color-primary-dark-200);
  --fin-accordion-item-dark-title-color: var(--fin-color-base-white);
  --fin-accordion-item-dark-description-color: var(--fin-color-gray-cool-200);
  --fin-accordion-item-dark-icon-color: var(--fin-color-base-white);
  --fin-accordion-item-dark-border-color: var(--fin-color-gray-warm-100);

  --fin-accordion-item-light-header-bg-color: var(--fin-color-gray-cool-50);
  --fin-accordion-item-light-header-hover-bg-color: var(--fin-color-gray-cool-100);
  --fin-accordion-item-light-header-active-bg-color: var(--fin-color-gray-cool-200);
  --fin-accordion-item-light-header-focus-bg-color: var(--fin-color-gray-cool-50);
  --fin-accordion-item-light-header-focus-border-color: var(--fin-color-gray-cool-500);
  --fin-accordion-item-light-title-color: var(--fin-color-gray-cool-700);
  --fin-accordion-item-light-description-color: var(--fin-color-gray-cool-500);
  --fin-accordion-item-light-icon-color: var(--fin-color-gray-cool-700);
  --fin-accordion-item-light-border-color: var(--fin-color-gray-warm-100);

  --fin-accordion-item-file-uploader-header-bg-color: var(--fin-color-base-white);
  --fin-accordion-item-file-uploader-header-hover-bg-color: var(--fin-color-gray-cool-25);
  --fin-accordion-item-file-uploader-header-active-bg-color: var(--fin-color-gray-cool-50);
  --fin-accordion-item-file-uploader-header-focus-bg-color: var(--fin-color-gray-cool-25);
  --fin-accordion-item-file-uploader-header-focus-border-color: var(--fin-color-gray-cool-500);
  --fin-accordion-item-file-uploader-title-color: var(--fin-color-gray-cool-700);
  --fin-accordion-item-file-uploader-description-color: var(--fin-color-gray-cool-500);
  --fin-accordion-item-file-uploader-icon-color: var(--fin-color-gray-cool-700);
  --fin-accordion-item-file-uploader-border-color: var(--fin-color-gray-warm-100);

  --fin-accordion-item-gray-header-bg-color: var(--fin-color-gray-cool-25);
  --fin-accordion-item-gray-header-hover-bg-color: var(--fin-color-gray-cool-50);
  --fin-accordion-item-gray-header-active-bg-color: var(--fin-color-gray-cool-100);
  --fin-accordion-item-gray-header-focus-bg-color: var(--fin-color-gray-cool-50);
  --fin-accordion-item-gray-header-focus-border-color: var(--fin-color-gray-cool-500);
  --fin-accordion-item-gray-title-color: var(--fin-color-base-black);
  --fin-accordion-item-gray-description-color: var(--fin-color-gray-cool-500);
  --fin-accordion-item-gray-icon-color: var(--fin-color-gray-cool-700);
  --fin-accordion-item-gray-border-color: var(--fin-color-gray-cool-100);

  --fin-accordion-item-self-onboarding-faq-header-bg-color: var(--fin-color-gray-cool-50);
  --fin-accordion-item-self-onboarding-faq-header-hover-bg-color: var(--fin-color-gray-cool-100);
  --fin-accordion-item-self-onboarding-faq-header-active-bg-color: var(--fin-color-gray-cool-200);
  --fin-accordion-item-self-onboarding-faq-header-focus-bg-color: var(--fin-color-gray-cool-100);
  --fin-accordion-item-self-onboarding-faq-title-color: var(--fin-color-base-black);
  --fin-accordion-item-self-onboarding-faq-description-color: var(--fin-color-gray-cool-600);
  --fin-accordion-item-self-onboarding-faq-icon-color: var(--fin-color-base-black);
}

[tuiWrapper][data-appearance='fin-accordion-btn-primary'] {
  --fin-accordion-button-primary-bg-color: var(--fin-color-primary-light-500);
  --fin-accordion-button-primary-text-color: var(--fin-color-base-white);
  --fin-accordion-button-primary-hover-bg-color: var(--fin-color-primary-light-400);
  --fin-accordion-button-primary-hover-text-color: var(--fin-color-base-white);
  --fin-accordion-button-primary-active-bg-color: var(--fin-color-primary-light-700);
  --fin-accordion-button-primary-active-text-color: var(--fin-color-base-white);
  --fin-accordion-button-primary-focus-bg-color: var(--fin-color-primary-light-400);
  --fin-accordion-button-primary-focus-border-color: var(--fin-color-primary-light-700);
  --fin-accordion-button-primary-disabled-bg-color: var(--fin-color-primary-dark-400);
  --fin-accordion-button-primary-disabled-text-color: var(--fin-color-gray-cool-300);
}

[tuiWrapper][data-appearance='fin-accordion-icon-btn-accent-flat'] {
  --fin-accordion-icon-button-accent-flat-bg-color: var(--fin-color-gray-cool-700);
  --fin-accordion-icon-button-accent-flat-icon-color: var(--fin-color-gray-cool-700);
  --fin-accordion-icon-button-accent-flat-hover-bg-color: var(--fin-color-error-200);
  --fin-accordion-icon-button-accent-flat-hover-icon-color: var(--fin-color-error-500);
  --fin-accordion-icon-button-accent-flat-active-bg-color: var(--fin-color-error-300);
  --fin-accordion-icon-button-accent-flat-active-icon-color: var(--fin-color-error-700);
  --fin-accordion-icon-button-accent-flat-focus-bg-color: var(--fin-color-error-200);
  --fin-accordion-icon-button-accent-flat-focus-icon-color: var(--fin-color-error-500);
  --fin-accordion-icon-button-accent-flat-focus-border-color: var(--fin-color-error-500);
  --fin-accordion-icon-button-accent-flat-disabled-bg-color: var(--fin-color-gray-cool-700);
  --fin-accordion-icon-button-accent-flat-disabled-icon-color: var(--fin-color-gray-cool-400);
}

[tuiWrapper][data-appearance='fin-accordion-icon-btn-accent'] {
  --fin-accordion-icon-button-accent-bg-color: var(--fin-color-gray-cool-700);
  --fin-accordion-icon-button-accent-icon-color: var(--fin-color-base-white);
  --fin-accordion-icon-button-accent-hover-bg-color: var(--fin-color-error-200);
  --fin-accordion-icon-button-accent-hover-icon-color: var(--fin-color-error-500);
  --fin-accordion-icon-button-accent-active-bg-color: var(--fin-color-error-300);
  --fin-accordion-icon-button-accent-active-icon-color: var(--fin-color-error-700);
  --fin-accordion-icon-button-accent-focus-bg-color: var(--fin-color-error-200);
  --fin-accordion-icon-button-accent-focus-icon-color: var(--fin-color-error-500);
  --fin-accordion-icon-button-accent-focus-border-color: var(--fin-color-error-500);
  --fin-accordion-icon-button-accent-disabled-bg-color: var(--fin-color-gray-cool-700);
  --fin-accordion-icon-button-accent-disabled-icon-color: var(--fin-color-base-white);
}

[tuiWrapper][data-appearance='fin-accordion-third-btn'] {
  --fin-accordion-button-third-border-color: var(--fin-color-gray-cool-100);
  --fin-accordion-button-third-text-color: var(--fin-color-base-white);
  --fin-accordion-button-third-hover-border-color: var(--fin-color-gray-warm-100);
  --fin-accordion-button-third-hover-bg-color: var(--fin-color-primary-dark-400);
  --fin-accordion-button-third-hover-text-color: var(--fin-color-base-white);
  --fin-accordion-button-third-active-bg-color: var(--fin-color-primary-light-100);
  --fin-accordion-button-third-active-text-color: var(--fin-color-primary-light-700);
  --fin-accordion-button-third-active-border-color: var(--fin-color-gray-cool-100);
  --fin-accordion-button-third-focus-bg-color: var(--fin-color-primary-light-50);
  --fin-accordion-button-third-focus-border-color: var(--fin-color-primary-light-700);
  --fin-accordion-button-third-focus-text-color: var(--fin-color-primary-light-500);
  --fin-accordion-button-third-disabled-bg-color: var(--fin-color-primary-dark-400);
  --fin-accordion-button-third-disabled-text-color: var(--fin-color-gray-cool-300);
}
