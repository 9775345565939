.fin-dialog-container {
  .fin-dialog-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 24px 8px;

    h2.fin-dialog-header-title {
      color: var(--fin-dialog-header-text-color);
      font-size: 28px;
      font-style: normal;
      font-weight: 800;
      line-height: 32px;
      margin: 0;
    }
  }

  .fin-dialog-content {
    padding: 16px 24px;
  }

  .fin-dialog-actions {
    display: flex;
    padding: 16px 24px;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    border-top: 1px solid var(--fin-dialog-actions-border-color);
  }
}
