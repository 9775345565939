.row {
  display: flex;
}

.col {
  display: flex;
  flex-direction: column;
}

.row-wrap {
  @extend .row;
  flex-wrap: wrap;
}

.col-wrap {
  @extend .col;
  flex-wrap: wrap;
}

.justify-start {
  justify-content: start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.align-start {
  align-items: start;
}

.align-flex-end {
  align-items: flex-end;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: end;
}

.cursor-pointer {
  cursor: pointer;
}

.truncate-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &::after {
    content: '';
    display: block;
  }
}

.m-t-1 {
  margin-top: 1rem;
}

.w-full {
  width: 100%;
}
