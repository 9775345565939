@use '@taiga-ui/core/styles/mixins/wrapper' as wrapper;

.fin-data-grid-actions-cell-template-appearance {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;

  [tuiWrapper][data-appearance='fin-data-grid-actions-cell-primary'] {
    background: var(--fin-data-grid-actions-cell-primary-action-bg-color);
    color: var(--fin-data-grid-actions-cell-primary-action-color);

    @include wrapper.wrapper-hover {
      color: var(--fin-data-grid-actions-cell-primary-action-hover-color);
    }
    @include wrapper.wrapper-active {
      color: var(--fin-data-grid-actions-cell-primary-action-active-color);
    }
    @include wrapper.wrapper-disabled {
      opacity: var(--tui-disabled-opacity);
    }
    @include wrapper.wrapper-focus {
      border-color: var(--fin-data-grid-actions-cell-primary-action-focus-border-color);
    }
  }

  [tuiWrapper][data-appearance='fin-data-grid-actions-cell-primary-btn'] {
    background: var(--fin-data-grid-actions-cell-primary-action-btn-bg-color);
    color: var(--fin-data-grid-actions-cell-primary-action-btn-color);

    @include wrapper.wrapper-hover {
      color: var(--fin-data-grid-actions-cell-primary-action-hover-color);
    }
    @include wrapper.wrapper-active {
      color: var(--fin-data-grid-actions-cell-prqwimary-action-active-color);
    }
    @include wrapper.wrapper-disabled {
      opacity: var(--tui-disabled-opacity);
    }
    @include wrapper.wrapper-focus {
      border-color: var(--fin-data-grid-actions-cell-primary-action-focus-border-color);
    }
  }

  [tuiWrapper][data-appearance='fin-data-grid-actions-cell-secondary'] {
    background: var(--fin-data-grid-actions-cell-secondary-action-bg-color);
    color: var(--fin-data-grid-actions-cell-secondary-action-color);

    @include wrapper.wrapper-hover {
      color: var(--fin-data-grid-actions-cell-secondary-action-hover-color);
    }
    @include wrapper.wrapper-active {
      color: var(--fin-data-grid-actions-cell-secondary-action-active-color);
    }
    @include wrapper.wrapper-disabled {
      opacity: var(--tui-disabled-opacity);
    }
    @include wrapper.wrapper-focus {
      border-color: var(--fin-data-grid-actions-cell-secondary-action-focus-border-color);
    }
  }

  [tuiWrapper][data-appearance='fin-data-grid-actions-cell-danger'] {
    background: var(--fin-data-grid-actions-cell-danger-action-bg-color);
    color: var(--fin-data-grid-actions-cell-danger-action-color);

    @include wrapper.wrapper-hover {
      color: var(--fin-data-grid-actions-cell-danger-action-hover-color);
    }
    @include wrapper.wrapper-active {
      color: var(--fin-data-grid-actions-cell-danger-action-active-color);
    }
    @include wrapper.wrapper-disabled {
      opacity: var(--tui-disabled-opacity);
    }
    @include wrapper.wrapper-focus {
      border-color: var(--fin-data-grid-actions-cell-danger-action-focus-border-color);
    }
  }
}
