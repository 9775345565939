.fin-info-dialog {
  --fin-info-dialog-bg-color: var(--fin-color-base-white);
  --fin-info-dialog-header-border-bottom-color: var(--fin-color-gray-warm-100);
  --fin-info-dialog-marker-icon-primary-bg-color: var(--fin-color-primary-light-100);
  --fin-info-dialog-marker-icon-primary-color: var(--fin-color-primary-light-500);
  --fin-info-dialog-marker-icon-warning-bg-color: var(--fin-color-error-100);
  --fin-info-dialog-marker-icon-warning-color: var(--fin-color-error-500);
  --fin-info-dialog-header-text-color: var(--fin-color-base-black);
  --fin-info-dialog-content-text-color: var(--fin-color-base-black);
}
