tui-notification[data-status='info'] {
  --fin-notification-bg-color: var(--fin-color-primary-light-50);
  --fin-notification-icon-color: var(--fin-color-primary-light-400);
}

tui-notification[data-status='success'] {
  --fin-notification-bg-color: var(--fin-color-success-50);
  --fin-notification-icon-color: var(--fin-color-success-500);
}

tui-notification[data-status='warning'] {
  --fin-notification-bg-color: var(--fin-color-warning-50);
  --fin-notification-icon-color: var(--fin-color-warning-400);
}

tui-notification[data-status='error'] {
  --fin-notification-bg-color: var(--fin-color-error-50);
  --fin-notification-icon-color: var(--fin-color-error-500);
}

tui-notification {
  --fin-notification-content-title-color: var(--fin-color-gray-warm-700);
  --fin-notification-content-message-color: var(--fin-color-gray-warm-700);

  // Action button disabled theme - shared for all types of notifications
  --fin-notification-action-btn-disabled-bg-color: var(--fin-color-gray-cool-100);
  --fin-notification-action-btn-disabled-text-color: var(--fin-color-gray-cool-300);
  --fin-notification-action-btn-disabled-border-color: var(--fin-color-gray-cool-200);

  // Action button secondary - shared for all types of notifications
  --fin-notification-action-btn-secondary-text-color: var(--fin-color-gray-cool-700);
  --fin-notification-action-btn-secondary-border-color: var(--fin-color-gray-cool-200);
  --fin-notification-action-btn-secondary-hover-bg-color: var(--fin-color-gray-warm-100);
  --fin-notification-action-btn-secondary-hover-text-color: var(--fin-color-gray-cool-700);
  --fin-notification-action-btn-secondary-hover-border-color: var(--fin-color-gray-warm-200);
  --fin-notification-action-btn-secondary-active-bg-color: var(--fin-color-gray-warm-100);
  --fin-notification-action-btn-secondary-active-text-color: var(--fin-color-gray-cool-700);
  --fin-notification-action-btn-secondary-active-border-color: var(--fin-color-gray-cool-100);
  --fin-notification-action-btn-secondary-focus-bg-color: var(--fin-color-gray-warm-100);
  --fin-notification-action-btn-secondary-focus-text-color: var(--fin-color-gray-cool-700);
  --fin-notification-action-btn-secondary-focus-border-color: var(var(--fin-color-gray-cool-500));

  // Link button secondary theme - shared for all types of notifications
  --fin-notification-link-btn-secondary-text-decoration-color: var(--fin-color-gray-cool-600);
  --fin-notification-link-btn-secondary-text-color: var(--fin-color-gray-cool-600);
  --fin-notification-link-btn-secondary-hover-text-color: var(--fin-color-gray-cool-400);
  --fin-notification-link-btn-secondary-active-text-color: var(--fin-color-gray-cool-800);
  --fin-notification-link-btn-secondary-focus-text-color: var(--fin-color-gray-cool-400);
  --fin-notification-link-btn-secondary-disabled-text-color: var(--fin-color-gray-cool-300);

  // Action button primary - info
  &[data-status='info'] {
    // Action button primary - info
    --fin-notification-action-btn-primary-text-color: var(--fin-color-primary-light-500);
    --fin-notification-action-btn-primary-border-color: var(--fin-color-primary-light-500);
    --fin-notification-action-btn-primary-hover-bg-color: var(--fin-color-primary-light-50);
    --fin-notification-action-btn-primary-hover-text-color: var(--fin-color-primary-light-500);
    --fin-notification-action-btn-primary-hover-border-color: var(--fin-color-primary-light-500);
    --fin-notification-action-btn-primary-active-bg-color: var(--fin-color-primary-light-100);
    --fin-notification-action-btn-primary-active-text-color: var(--fin-color-primary-light-700);
    --fin-notification-action-btn-primary-active-border-color: var(--fin-color-primary-light-700);
    --fin-notification-action-btn-primary-focus-bg-color: var(--fin-color-primary-light-100);
    --fin-notification-action-btn-primary-focus-text-color: var(--fin-color-primary-light-500);
    --fin-notification-action-btn-primary-focus-border-color: var(--fin-color-primary-light-500);

    // Link button primary - info
    --fin-notification-link-btn-primary-text-decoration-color: var(--fin-color-primary-light-300);
    --fin-notification-link-btn-primary-text-color: var(--fin-color-primary-light-500);
    --fin-notification-link-btn-primary-hover-text-color: var(--fin-color-gray-cool-400);
    --fin-notification-link-btn-primary-hover-bg-color: var(--fin-color-primary-light-100);
    --fin-notification-link-btn-primary-active-text-color: var(--fin-color-primary-light-700);
    --fin-notification-link-btn-primary-focus-text-color: var(--fin-color-gray-cool-300);
    --fin-notification-link-btn-primary-disabled-text-color: var(--fin-color-gray-cool-300);
  }

  &[data-status='success'] {
    // Action button primary - success
    --fin-notification-action-btn-primary-text-color: var(--fin-color-success-500);
    --fin-notification-action-btn-primary-border-color: var(--fin-color-success-500);
    --fin-notification-action-btn-primary-hover-bg-color: var(--fin-color-success-50);
    --fin-notification-action-btn-primary-hover-text-color: var(--fin-color-success-500);
    --fin-notification-action-btn-primary-hover-border-color: var(--fin-color-success-500);
    --fin-notification-action-btn-primary-active-bg-color: var(--fin-color-success-100);
    --fin-notification-action-btn-primary-active-text-color: var(--fin-color-success-700);
    --fin-notification-action-btn-primary-active-border-color: var(--fin-color-success-500);
    --fin-notification-action-btn-primary-focus-bg-color: var(--fin-color-success-100);
    --fin-notification-action-btn-primary-focus-text-color: var(--fin-color-success-500);
    --fin-notification-action-btn-primary-focus-border-color: var(--fin-color-success-500);

    // Link button primary - success
    --fin-notification-link-btn-primary-text-decoration-color: var(--fin-color-success-300);
    --fin-notification-link-btn-primary-text-color: var(--fin-color-success-500);
    --fin-notification-link-btn-primary-hover-text-color: var(--fin-color-success-500);
    --fin-notification-link-btn-primary-hover-bg-color: var(--fin-color-success-100);
    --fin-notification-link-btn-primary-active-text-color: var(--fin-color-success-700);
    --fin-notification-link-btn-primary-focus-text-color: var(--fin-color-success-500);
    --fin-notification-link-btn-primary-disabled-text-color: var(--fin-color-gray-cool-300);
  }

  &[data-status='warning'] {
    // Action button primary - warning
    --fin-notification-action-btn-primary-text-color: var(--fin-color-warning-400);
    --fin-notification-action-btn-primary-border-color: var(--fin-color-warning-400);
    --fin-notification-action-btn-primary-hover-bg-color: var(--fin-color-warning-25);
    --fin-notification-action-btn-primary-hover-text-color: var(--fin-color-warning-400);
    --fin-notification-action-btn-primary-hover-border-color: var(--fin-color-warning-400);
    --fin-notification-action-btn-primary-active-bg-color: var(--fin-color-warning-50);
    --fin-notification-action-btn-primary-active-text-color: var(--fin-color-warning-600);
    --fin-notification-action-btn-primary-active-border-color: var(--fin-color-warning-600);
    --fin-notification-action-btn-primary-focus-bg-color: var(--fin-color-warning-400);
    --fin-notification-action-btn-primary-focus-text-color: var(--fin-color-warning-400);
    --fin-notification-action-btn-primary-focus-border-color: var(--fin-color-warning-50);

    // Link button primary - warning
    --fin-notification-link-btn-primary-text-decoration-color: var(--fin-color-warning-300);
    --fin-notification-link-btn-primary-text-color: var(--fin-color-warning-500);
    --fin-notification-link-btn-primary-hover-text-color: var(--fin-color-warning-500);
    --fin-notification-link-btn-primary-hover-bg-color: var(--fin-color-warning-100);
    --fin-notification-link-btn-primary-active-text-color: var(--fin-color-warning-700);
    --fin-notification-link-btn-primary-focus-text-color: var(--fin-color-warning-500);
    --fin-notification-link-btn-primary-disabled-text-color: var(--fin-color-gray-cool-300);
  }

  &[data-status='error'] {
    // Action button primary - error
    --fin-notification-action-btn-primary-text-color: var(--fin-color-error-500);
    --fin-notification-action-btn-primary-border-color: var(--fin-color-error-500);
    --fin-notification-action-btn-primary-hover-bg-color: var(--fin-color-error-50);
    --fin-notification-action-btn-primary-hover-text-color: var(--fin-color-error-500);
    --fin-notification-action-btn-primary-hover-border-color: var(--fin-color-error-500);
    --fin-notification-action-btn-primary-active-bg-color: var(--fin-color-warning-50);
    --fin-notification-action-btn-primary-active-text-color: var(--fin-color-error-700);
    --fin-notification-action-btn-primary-active-border-color: var(--fin-color-error-700);
    --fin-notification-action-btn-primary-focus-bg-color: var(--fin-color-error-100);
    --fin-notification-action-btn-primary-focus-text-color: var(--fin-color-error-500);
    --fin-notification-action-btn-primary-focus-border-color: var(--fin-color-error-500);

    // Link button primary - error
    --fin-notification-link-btn-primary-text-decoration-color: var(--fin-color-error-300);
    --fin-notification-link-btn-primary-text-color: var(--fin-color-error-500);
    --fin-notification-link-btn-primary-hover-text-color: var(--fin-color-error-500);
    --fin-notification-link-btn-primary-hover-bg-color: var(--fin-color-error-100);
    --fin-notification-link-btn-primary-active-text-color: var(--fin-color-error-700);
    --fin-notification-link-btn-primary-focus-text-color: var(--fin-color-error-500);
    --fin-notification-link-btn-primary-disabled-text-color: var(--fin-color-gray-cool-300);
  }
}
