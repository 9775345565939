@use 'mixins/mixins' as fin-mixins;

tui-notification {
  border-radius: 12px !important;
  padding: 12px 16px !important; // We support only size "m" for now
  background: var(--fin-notification-bg-color) !important;
  color: var(--fin-notification-icon-color) !important;

  .buttons-container {
    display: flex;
    gap: 20px;
    margin-top: 12px;
  }
}

[tuiWrapper][data-appearance='fin-notification-action-btn-primary'] {
  @include fin-mixins.button-appearance(
    (
      bg-color: null,
      border-color: var(--fin-notification-action-btn-primary-border-color),
      text-color: var(--fin-notification-action-btn-primary-text-color),
      hover-bg-color: var(--fin-notification-action-btn-primary-hover-bg-color),
      hover-border-color: var(--fin-notification-action-btn-primary-hover-border-color),
      hover-text-color: var(--fin-notification-action-btn-primary-hover-text-color),
      active-bg-color: var(--fin-notification-action-btn-primary-active-bg-color),
      active-border-color: var(--fin-notification-action-btn-primary-active-border-color),
      active-text-color: var(--fin-notification-action-btn-primary-active-text-color),
      focus-bg-color: var(--fin-notification-action-btn-primary-focus-bg-color),
      focus-border-color: var(--fin-notification-action-btn-primary-focus-border-color),
      focus-text-color: var(--fin-notification-action-btn-primary-focus-text-color),
      disabled-bg-color: var(--fin-notification-action-btn-disabled-bg-color),
      disabled-border-color: var(--fin-notification-action-btn-disabled-border-color),
      disabled-text-color: var(--fin-notification-action-btn-disabled-text-color),
    ),
    (
      border-width: 1px,
      focus-border-width: 2px,
    )
  );
}

[tuiWrapper][data-appearance='fin-notification-action-btn-secondary'] {
  @include fin-mixins.button-appearance(
    (
      bg-color: null,
      border-color: var(--fin-notification-action-btn-secondary-border-color),
      text-color: var(--fin-notification-action-btn-secondary-text-color),
      hover-bg-color: var(--fin-notification-action-btn-secondary-hover-bg-color),
      hover-border-color: var(--fin-notification-action-btn-secondary-hover-border-color),
      hover-text-color: var(--fin-notification-action-btn-secondary-hover-text-color),
      active-bg-color: var(--fin-notification-action-btn-secondary-active-bg-color),
      active-border-color: var(--fin-notification-action-btn-secondary-active-border-color),
      active-text-color: var(--fin-notification-action-btn-secondary-active-text-color),
      focus-bg-color: var(--fin-notification-action-btn-secondary-focus-bg-color),
      focus-border-color: var(--fin-notification-action-btn-secondary-focus-border-color),
      focus-text-color: var(--fin-notification-action-btn-secondary-focus-text-color),
      disabled-bg-color: var(--fin-notification-action-btn-disabled-bg-color),
      disabled-border-color: var(--fin-notification-action-btn-disabled-border-color),
      disabled-text-color: var(--fin-notification-action-btn-disabled-text-color),
    ),
    (
      border-width: 1px,
      focus-border-width: 2px,
    )
  );
}

button[tuiLink].notification-link-btn {
  display: flex;
  height: 24px;
  padding: 0;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;

  .t-content {
    background: none !important; // eliminate tui ._focus-visible styles
  }

  .link-button-content {
    padding: 0;
    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 141.176% */
    text-decoration-line: underline;

    &.fin-notification-link-btn-primary {
      color: var(--fin-notification-link-btn-primary-text-color);
    }

    &.fin-notification-link-btn-secondary {
      color: var(--fin-notification-link-btn-secondary-text-color);
    }
  }

  &:hover:not(:active):not(:disabled) {
    .link-button-content {
      &.fin-notification-link-btn-primary {
        color: var(--fin-notification-link-btn-primary-hover-text-color);
      }

      &.fin-notification-link-btn-secondary {
        color: var(--fin-notification-link-btn-secondary-hover-text-color);
      }
    }
  }

  &:active {
    .link-button-content {
      &.fin-notification-link-btn-primary {
        color: var(--fin-notification-link-btn-primary-active-text-color);
      }

      &.fin-notification-link-btn-secondary {
        color: var(--fin-notification-link-btn-secondary-active-text-color);
      }
    }
  }

  &:focus:not(:active):not(:disabled) {
    .link-button-content {
      &.fin-notification-link-btn-primary {
        color: var(--fin-notification-link-btn-primary-focus-text-color);
      }

      &.fin-notification-link-btn-secondary {
        color: var(--fin-notification-link-btn-secondary-focus-text-color);
      }
    }
  }

  &:disabled {
    .link-button-content {
      &.fin-notification-link-btn-primary {
        color: var(--fin-notification-link-btn-primary-disabled-text-color);
      }

      &.fin-notification-link-btn-secondary {
        color: var(--fin-notification-link-btn-secondary-disabled-text-color);
      }
    }
  }
}
