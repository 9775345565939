label._control,
label.fin-input-field-label._control {
  --fin-input-field-label-text-color: var(--fin-color-gray-cool-600);
  --fin-input-field-label-required-indicator-color: var(--fin-color-error-500);
}

[tuiWrapper][data-appearance='fin-textfield'] {
  // Default state
  --fin-input-field-text-color: var(--fin-color-base-black);
  --fin-input-field-bg-color: var(--fin-color-base-white);
  --fin-input-field-border-color: var(--fin-color-gray-cool-200);
  --fin-input-field-placeholder-color: var(--fin-color-gray-cool-300);
  --fin-input-field-icon-cleaner-color: var(--fin-color-gray-cool-300);
  --fin-input-field-box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);

  // Hover
  --fin-input-field-hover-text-color: var(--fin-color-base-black);
  --fin-input-field-hover-bg-color: var(--fin-color-base-white);
  --fin-input-field-hover-border-color: var(--fin-color-gray-cool-200);
  --fin-input-field-box-hover-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.16);

  // Active
  --fin-input-field-active-text-color: var(--fin-color-base-black);
  --fin-input-field-active-bg-color: var(--fin-color-base-white);
  --fin-input-field-active-border-color: var(--fin-color-primary-light-500);
  --fin-input-field-active-box-shadow: none;

  // Disabled/Read Only
  --fin-input-field-disabled-text-color: var(--fin-color-gray-cool-300);
  --fin-input-field-disabled-bg-color: var(--fin-color-gray-cool-50);
  --fin-input-field-disabled-border-color: var(--fin-color-gray-cool-200);
  --fin-input-field-disabled-box-shadow: none;

  // Error
  --fin-input-field-error-text-color: var(--fin-color-base-black);
  --fin-input-field-error-bg-color: var(--fin-color-base-white);
  --fin-input-field-error-border-color: var(--fin-color-error-500);
  --fin-input-field-error-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.16);
}
