tui-hint[data-appearance='fin-hint-white'] {
  background: var(--fin-hint-content-bg-color);
  color: var(--fin-hint-content-text-color);
  box-shadow: var(--fin-hint-content-box-shadow);
  border-radius: 12px;
}

tui-tooltip[data-appearance='fin-hint-white'] {
  width: 16px;
  height: 16px;

  .t-tooltip-icon > tui-svg {
    width: 16px;
    height: 16px;
  }
}
