.fin-data-grid-actions-cell-template-appearance {
  // Primary action
  --fin-data-grid-actions-cell-primary-action-bg-color: transparent;
  --fin-data-grid-actions-cell-primary-action-color: var(--fin-color-gray-cool-400);
  --fin-data-grid-actions-cell-primary-action-hover-color: var(--fin-color-primary-light-500);
  --fin-data-grid-actions-cell-primary-action-active-color: var(--fin-color-primary-light-600);
  --fin-data-grid-actions-cell-primary-action-focus-border-color: var(
    --fin-data-grid-actions-cell-primary-action-color
  );

  --fin-data-grid-actions-cell-primary-action-btn-bg-color: var(--fin-color-primary-light-50);
  --fin-data-grid-actions-cell-primary-action-btn-color: var(--fin-color-primary-light-500);

  // Secondary action
  --fin-data-grid-actions-cell-secondary-action-bg-color: transparent;
  --fin-data-grid-actions-cell-secondary-action-color: var(--fin-color-gray-cool-400);
  --fin-data-grid-actions-cell-secondary-action-hover-color: var(--fin-color-gray-cool-500);
  --fin-data-grid-actions-cell-secondary-action-active-color: var(--fin-color-gray-cool-600);
  --fin-data-grid-actions-cell-secondary-action-focus-border-color: var(
    --fin-data-grid-actions-cell-secondary-action-color
  );

  // Danger action
  --fin-data-grid-actions-cell-danger-action-bg-color: transparent;
  --fin-data-grid-actions-cell-danger-action-color: var(--fin-color-gray-cool-400);
  --fin-data-grid-actions-cell-danger-action-hover-color: var(--fin-color-error-500);
  --fin-data-grid-actions-cell-danger-action-active-color: var(--fin-color-error-600);
  --fin-data-grid-actions-cell-danger-action-focus-border-color: var(--fin-data-grid-actions-cell-danger-action-color);
}
