fin-toggle-labeled {
  --fin-toggle-label-text-color: var(--fin-color-base-black);
  --fin-toggle-desc-text-color: var(--fin-color-gray-cool-500);
}

[tuiWrapper][data-appearance='fin-toggle-dark-unselected'] {
  --fin-toggle-dark-unselected-bg-color: var(--fin-color-gray-cool-200);
  --fin-toggle-dark-unselected-border-color: var(--fin-color-gray-cool-100);
  --fin-toggle-dark-unselected-hover-bg-color: var(--fin-color-gray-cool-100);
  --fin-toggle-dark-unselected-hover-border-color: var(--fin-color-gray-cool-300);
  --fin-toggle-dark-unselected-active-bg-color: var(--fin-color-gray-cool-100);
  --fin-toggle-dark-unselected-active-border-color: var(--fin-color-gray-cool-500);
  --fin-toggle-dark-unselected-focus-bg-color: var(--fin-color-gray-cool-100);
  --fin-toggle-dark-unselected-focus-border-color: var(--fin-color-gray-cool-300);
  --fin-toggle-dark-unselected-disabled-bg-color: var(--fin-color-gray-cool-200);
  --fin-toggle-dark-unselected-disabled-border-color: var(--fin-color-gray-cool-100);
}

[tuiWrapper][data-appearance='fin-toggle-unselected'] {
  --fin-toggle-unselected-bg-color: var(--fin-color-gray-cool-50);
  --fin-toggle-unselected-border-color: var(--fin-color-gray-cool-200);
  --fin-toggle-unselected-hover-bg-color: var(--fin-color-gray-cool-100);
  --fin-toggle-unselected-hover-border-color: var(--fin-color-gray-cool-300);
  --fin-toggle-unselected-active-bg-color: var(--fin-color-gray-cool-200);
  --fin-toggle-unselected-active-border-color: var(--fin-color-gray-cool-500);
  --fin-toggle-unselected-focus-bg-color: var(--fin-color-gray-cool-100);
  --fin-toggle-unselected-focus-border-color: var(--fin-color-gray-cool-300);
  --fin-toggle-unselected-disabled-bg-color: var(--fin-color-gray-cool-50);
  --fin-toggle-unselected-disabled-border-color: var(--fin-color-gray-cool-100);
}

[tuiWrapper][data-appearance='fin-toggle-selected'] {
  --fin-toggle-selected-bg-color: var(--fin-color-primary-light-500);
  --fin-toggle-selected-border-color: transparent;
  --fin-toggle-selected-hover-bg-color: var(--fin-color-primary-light-400);
  --fin-toggle-selected-hover-border-color: transparent;
  --fin-toggle-selected-active-bg-color: var(--fin-color-primary-dark-500);
  --fin-toggle-selected-active-border-color: transparent;
  --fin-toggle-selected-focus-bg-color: var(--fin-color-primary-light-700);
  --fin-toggle-selected-focus-border-color: transparent;
  --fin-toggle-selected-disabled-bg-color: var(--fin-color-primary-light-200);
  --fin-toggle-selected-disabled-border-color: transparent;
}
