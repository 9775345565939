@use '@taiga-ui/core/styles/mixins/wrapper' as wrapper;

// --------------------------------------
// Primary Button
// --------------------------------------
[tuiWrapper][data-appearance='fin-radio-button-unselected'] {
  background: var(--fin-radio-unselected-bg-color);
  color: var(--fin-radio-unselected-text-color);
  &:after {
    border: 1px solid var(--fin-radio-unselected-border-color);
  }

  @include wrapper.wrapper-hover {
    background: var(--fin-radio-unselected-hover-bg-color);
    &:after {
      border-color: var(--fin-radio-unselected-hover-border-color);
    }
  }
  @include wrapper.wrapper-active {
    background: var(--fin-radio-unselected-active-bg-color);
    &:after {
      border-color: var(--fin-radio-unselected-active-border-color);
    }
  }
  @include wrapper.wrapper-focus {
    background: var(--fin-radio-unselected-focus-bg-color);
    &:after {
      border: 1px solid var(--fin-radio-unselected-focus-border-color);
    }
  }
  @include wrapper.wrapper-disabled {
    opacity: 1;
    background: var(--fin-radio-unselected-disabled-bg-color);
    &:after {
      border-color: var(--fin-radio-unselected-disabled-border-color);
    }
  }
}

[tuiWrapper][data-appearance='fin-radio-button-selected'] {
  background: var(--fin-radio-selected-bg-color);
  color: var(--fin-radio-selected-text-color);
  &:after {
    border: 1px solid var(--fin-radio-selected-border-color);
  }

  @include wrapper.wrapper-hover {
    background: var(--fin-radio-selected-hover-bg-color);
    &:after {
      border-color: var(--fin-radio-selected-hover-border-color);
    }
  }
  @include wrapper.wrapper-active {
    background: var(--fin-radio-selected-active-bg-color);
    &:after {
      border-color: var(--fin-radio-selected-active-border-color);
    }
  }
  @include wrapper.wrapper-focus {
    background: var(--fin-radio-selected-focus-bg-color);
    &:after {
      border: 1px solid var(--fin-radio-selected-focus-border-color);
    }
  }
  @include wrapper.wrapper-disabled {
    opacity: 1;
    background: var(--fin-radio-selected-disabled-bg-color);
    &:after {
      border-color: var(--fin-radio-selected-disabled-border-color);
    }
  }
}
