tui-checkbox-labeled {
  --fin-checkbox-label-text-color: var(--fin-color-base-black);
  --fin-checkbox-desc-text-color: var(--fin-color-gray-cool-500);
}

[tuiWrapper][data-appearance='fin-checkbox-unselected'] {
  --fin-checkbox-unselected-text-color: var(--fin-color-base-white);
  --fin-checkbox-unselected-bg-color: var(--fin-color-gray-cool-50);
  --fin-checkbox-unselected-border-color: var(--fin-color-gray-cool-200);
  --fin-checkbox-unselected-hover-bg-color: var(--fin-color-gray-cool-100);
  --fin-checkbox-unselected-hover-border-color: var(--fin-color-gray-cool-300);
  --fin-checkbox-unselected-active-bg-color: var(--fin-color-gray-cool-200);
  --fin-checkbox-unselected-active-border-color: var(--fin-color-gray-cool-500);
  --fin-checkbox-unselected-focus-bg-color: var(--fin-color-gray-cool-100);
  --fin-checkbox-unselected-focus-border-color: var(--fin-color-gray-cool-300);
  --fin-checkbox-unselected-disabled-bg-color: var(--fin-color-gray-cool-25);
  --fin-checkbox-unselected-disabled-border-color: var(--fin-color-gray-cool-100);
}

[tuiWrapper][data-appearance='fin-checkbox-selected'] {
  --fin-checkbox-selected-text-color: var(--fin-color-base-white);
  --fin-checkbox-selected-bg-color: var(--fin-color-primary-light-500);
  --fin-checkbox-selected-border-color: transparent;
  --fin-checkbox-selected-hover-bg-color: var(--fin-color-primary-light-400);
  --fin-checkbox-selected-hover-border-color: transparent;
  --fin-checkbox-selected-active-bg-color: var(--fin-color-primary-dark-500);
  --fin-checkbox-selected-active-border-color: transparent;
  --fin-checkbox-selected-focus-bg-color: var(--fin-color-primary-light-700);
  --fin-checkbox-selected-focus-border-color: transparent;
  --fin-checkbox-selected-disabled-bg-color: var(--fin-color-primary-light-200);
  --fin-checkbox-selected-disabled-border-color: transparent;
}
