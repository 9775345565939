@use 'mixins/mixins' as fin-mixins;

button[tuiButton][data-shape='rounded'],
button[tuiIconButton][data-shape='rounded'],
a[tuiButton][data-shape='rounded'],
a[tuiIconButton][data-shape='rounded'] {
  border-radius: 12px;
}

button[tuiButton][data-size='l'],
button[tuiIconButton][data-size='l'],
a[tuiButton][data-size='l'],
a[tuiIconButton][data-size='l'] {
  height: 56px;
  --tui-padding: 36px;
}

button[tuiButton][data-size='m'],
button[tuiIconButton][data-size='m'],
a[tuiButton][data-size='m'],
a[tuiIconButton][data-size='m'] {
  height: 44px;
  --tui-padding: 16px;
}

button[tuiButton][data-size='s'],
button[tuiIconButton][data-size='s'],
a[tuiButton][data-size='s'],
a[tuiIconButton][data-size='s'] {
  height: 32px;
  --tui-padding: 12px;
}

button[tuiButton][data-size='xs'],
button[tuiIconButton][data-size='xs'],
a[tuiButton][data-size='xs'],
a[tuiIconButton][data-size='xs'] {
  height: 24px;
  border-radius: 6px;
  --tui-padding: 6px;
}

// --------------------------------------
// Primary Button
// --------------------------------------
[tuiWrapper][data-appearance='fin-btn-primary'] {
  @include fin-mixins.button-appearance(
    (
      bg-color: var(--fin-button-primary-bg-color),
      border-color: null,
      text-color: var(--fin-button-primary-text-color),
      hover-bg-color: var(--fin-button-primary-hover-bg-color),
      hover-border-color: null,
      hover-text-color: var(--fin-button-primary-hover-text-color),
      active-bg-color: var(--fin-button-primary-active-bg-color),
      active-border-color: null,
      active-text-color: var(--fin-button-primary-active-text-color),
      focus-bg-color: var(--fin-button-primary-focus-bg-color),
      focus-border-color: var(--fin-button-primary-focus-border-color),
      focus-text-color: var(--fin-button-primary-text-color),
      disabled-bg-color: var(--fin-button-primary-disabled-bg-color),
      disabled-border-color: null,
      disabled-text-color: var(--fin-button-primary-disabled-text-color),
    )
  );
}

// --------------------------------------
// Secondary Button
// --------------------------------------
[tuiWrapper][data-appearance='fin-btn-secondary'] {
  @include fin-mixins.button-appearance(
    (
      bg-color: var(--fin-button-secondary-bg-color),
      border-color: null,
      text-color: var(--fin-button-secondary-text-color),
      hover-bg-color: var(--fin-button-secondary-hover-bg-color),
      hover-border-color: null,
      hover-text-color: var(--fin-button-secondary-hover-text-color),
      active-bg-color: var(--fin-button-secondary-active-bg-color),
      active-border-color: null,
      active-text-color: var(--fin-button-secondary-active-text-color),
      focus-bg-color: var(--fin-button-secondary-focus-bg-color),
      focus-border-color: var(--fin-button-secondary-focus-border-color),
      focus-text-color: var(--fin-button-secondary-focus-text-color),
      disabled-bg-color: var(--fin-button-secondary-disabled-bg-color),
      disabled-border-color: null,
      disabled-text-color: var(--fin-button-secondary-disabled-text-color),
    )
  );
}

// --------------------------------------
// Outline Button
// --------------------------------------
[tuiWrapper][data-appearance='fin-btn-outline'] {
  @include fin-mixins.button-appearance(
    (
      bg-color: var(--fin-button-outline-bg-color),
      border-color: var(--fin-button-outline-border-color),
      text-color: var(--fin-button-outline-text-color),
      hover-bg-color: var(--fin-button-outline-hover-bg-color),
      hover-border-color: var(--fin-button-outline-hover-border-color),
      hover-text-color: var(--fin-button-outline-hover-text-color),
      active-bg-color: var(--fin-button-outline-active-bg-color),
      active-border-color: var(--fin-button-outline-active-border-color),
      active-text-color: var(--fin-button-outline-active-text-color),
      focus-bg-color: var(--fin-button-outline-focus-bg-color),
      focus-border-color: var(--fin-button-outline-focus-border-color),
      focus-text-color: var(--fin-button-outline-focus-text-color),
      disabled-bg-color: var(--fin-button-outline-disabled-bg-color),
      disabled-border-color: var(--fin-button-outline-disabled-border-color),
      disabled-text-color: var(--fin-button-outline-disabled-text-color),
    )
  );
}

// --------------------------------------
// Flat Button
// --------------------------------------
[tuiWrapper][data-appearance='fin-btn-flat'] {
  @include fin-mixins.button-appearance(
    (
      bg-color: var(--fin-button-flat-bg-color),
      border-color: null,
      text-color: var(--fin-button-flat-text-color),
      hover-bg-color: var(--fin-button-flat-hover-bg-color),
      hover-border-color: null,
      hover-text-color: var(--fin-button-flat-hover-text-color),
      active-bg-color: var(--fin-button-flat-active-bg-color),
      active-border-color: null,
      active-text-color: var(--fin-button-flat-active-text-color),
      focus-bg-color: var(--fin-button-flat-focus-bg-color),
      focus-border-color: var(--fin-button-flat-focus-border-color),
      focus-text-color: var(--fin-button-flat-focus-text-color),
      disabled-bg-color: var(--fin-button-flat-disabled-bg-color),
      disabled-border-color: null,
      disabled-text-color: var(--fin-button-flat-disabled-text-color),
    )
  );
}

// --------------------------------------
// Secondary Gray Button
// --------------------------------------
[tuiWrapper][data-appearance='fin-btn-secondary-gray'] {
  @include fin-mixins.button-appearance(
    (
      bg-color: var(--fin-button-secondary-gray-bg-color),
      border-color: null,
      text-color: var(--fin-button-secondary-gray-text-color),
      hover-bg-color: var(--fin-button-secondary-gray-hover-bg-color),
      hover-border-color: null,
      hover-text-color: var(--fin-button-secondary-gray-hover-text-color),
      active-bg-color: var(--fin-button-secondary-gray-active-bg-color),
      active-border-color: null,
      active-text-color: var(--fin-button-secondary-gray-active-text-color),
      focus-bg-color: var(--fin-button-secondary-gray-focus-bg-color),
      focus-border-color: var(--fin-button-secondary-gray-focus-border-color),
      focus-text-color: var(--fin-button-secondary-gray-focus-text-color),
      disabled-bg-color: var(--fin-button-secondary-gray-disabled-bg-color),
      disabled-border-color: null,
      disabled-text-color: var(--fin-button-secondary-gray-disabled-text-color),
    )
  );
}

// --------------------------------------
// Outline Gray Button
// --------------------------------------
[tuiWrapper][data-appearance='fin-btn-outline-gray'] {
  @include fin-mixins.button-appearance(
    (
      bg-color: var(--fin-button-outline-gray-bg-color),
      border-color: var(--fin-button-outline-gray-border-color),
      text-color: var(--fin-button-outline-gray-text-color),
      hover-bg-color: var(--fin-button-outline-gray-hover-bg-color),
      hover-border-color: var(--fin-button-outline-gray-hover-border-color),
      hover-text-color: var(--fin-button-outline-gray-hover-text-color),
      active-bg-color: var(--fin-button-outline-gray-active-bg-color),
      active-border-color: var(--fin-button-outline-gray-active-border-color),
      active-text-color: var(--fin-button-outline-gray-active-text-color),
      focus-bg-color: var(--fin-button-outline-gray-focus-bg-color),
      focus-border-color: var(--fin-button-outline-gray-focus-border-color),
      focus-text-color: var(--fin-button-outline-gray-focus-text-color),
      disabled-bg-color: var(--fin-button-outline-gray-disabled-bg-color),
      disabled-border-color: var(--fin-button-outline-gray-disabled-border-color),
      disabled-text-color: var(--fin-button-outline-gray-disabled-text-color),
    )
  );
}

// --------------------------------------
// Flat Gray Button
// --------------------------------------
[tuiWrapper][data-appearance='fin-btn-flat-gray'] {
  @include fin-mixins.button-appearance(
    (
      bg-color: var(--fin-button-flat-gray-bg-color),
      border-color: null,
      text-color: var(--fin-button-flat-gray-text-color),
      hover-bg-color: var(--fin-button-flat-gray-hover-bg-color),
      hover-border-color: null,
      hover-text-color: var(--fin-button-flat-gray-hover-text-color),
      active-bg-color: var(--fin-button-flat-gray-active-bg-color),
      active-border-color: null,
      active-text-color: var(--fin-button-flat-gray-active-text-color),
      focus-bg-color: var(--fin-button-flat-gray-focus-bg-color),
      focus-border-color: var(--fin-button-flat-gray-focus-border-color),
      focus-text-color: var(--fin-button-flat-gray-focus-text-color),
      disabled-bg-color: var(--fin-button-flat-gray-disabled-bg-color),
      disabled-border-color: null,
      disabled-text-color: var(--fin-button-flat-gray-disabled-text-color),
    )
  );
}

// --------------------------------------
// Accent Button
// --------------------------------------
[tuiWrapper][data-appearance='fin-btn-accent'] {
  @include fin-mixins.button-appearance(
    (
      bg-color: var(--fin-button-accent-bg-color),
      border-color: null,
      text-color: var(--fin-button-accent-text-color),
      hover-bg-color: var(--fin-button-accent-hover-bg-color),
      hover-border-color: null,
      hover-text-color: var(--fin-button-accent-text-color),
      active-bg-color: var(--fin-button-accent-active-bg-color),
      active-border-color: null,
      active-text-color: var(--fin-button-accent-text-color),
      focus-bg-color: var(--fin-button-accent-bg-color),
      focus-border-color: var(--fin-button-accent-focus-border-color),
      focus-text-color: var(--fin-button-accent-text-color),
      disabled-bg-color: var(--fin-button-accent-disabled-bg-color),
      disabled-border-color: null,
      disabled-text-color: var(--fin-button-accent-disabled-text-color),
    )
  );
}

// --------------------------------------
// Outline Accent Button
// --------------------------------------
[tuiWrapper][data-appearance='fin-btn-outline-accent'] {
  @include fin-mixins.button-appearance(
    (
      bg-color: var(--fin-button-outline-accent-bg-color),
      border-color: var(--fin-button-outline-accent-border-color),
      text-color: var(--fin-button-outline-accent-text-color),
      hover-bg-color: var(--fin-button-outline-accent-hover-bg-color),
      hover-border-color: var(--fin-button-outline-accent-hover-border-color),
      hover-text-color: var(--fin-button-outline-accent-hover-text-color),
      active-bg-color: var(--fin-button-outline-accent-active-bg-color),
      active-border-color: var(--fin-button-outline-accent-active-border-color),
      active-text-color: var(--fin-button-outline-accent-active-text-color),
      focus-bg-color: var(--fin-button-outline-accent-focus-bg-color),
      focus-border-color: var(--fin-button-outline-accent-focus-border-color),
      focus-text-color: var(--fin-button-outline-accent-focus-text-color),
      disabled-bg-color: var(--fin-button-outline-accent-disabled-bg-color),
      disabled-border-color: var(--fin-button-outline-accent-disabled-border-color),
      disabled-text-color: var(--fin-button-outline-accent-disabled-text-color),
    )
  );
}

// --------------------------------------
// Accent Icon Button
// --------------------------------------
[tuiWrapper][data-appearance='fin-icon-btn-accent'] {
  @include fin-mixins.button-appearance(
    (
      bg-color: var(--fin-icon-button-accent-bg-color),
      border-color: null,
      text-color: var(--fin-icon-button-accent-icon-color),
      hover-bg-color: var(--fin-icon-button-accent-hover-bg-color),
      hover-border-color: null,
      hover-text-color: var(--fin-icon-button-accent-hover-icon-color),
      active-bg-color: var(--fin-icon-button-accent-active-bg-color),
      active-border-color: null,
      active-text-color: var(--fin-icon-button-accent-active-icon-color),
      focus-bg-color: var(--fin-icon-button-accent-focus-bg-color),
      focus-border-color: var(--fin-icon-button-accent-focus-border-color),
      focus-text-color: var(--fin-icon-button-accent-focus-icon-color),
      disabled-bg-color: var(--fin-icon-button-accent-disabled-bg-color),
      disabled-border-color: null,
      disabled-text-color: var(--fin-icon-button-accent-disabled-icon-color),
    ),
    (
      border-width: 1px,
    )
  );
}

// --------------------------------------
// Flat Accent Button
// --------------------------------------
[tuiWrapper][data-appearance='fin-btn-flat-accent'] {
  @include fin-mixins.button-appearance(
    (
      bg-color: var(--fin-button-flat-gray-bg-color),
      border-color: null,
      text-color: var(--fin-button-flat-gray-text-color),
      hover-bg-color: var(--fin-button-flat-gray-hover-bg-color),
      hover-border-color: null,
      hover-text-color: var(--fin-button-flat-gray-hover-text-color),
      active-bg-color: var(--fin-button-flat-gray-active-bg-color),
      active-border-color: null,
      active-text-color: var(--fin-button-flat-gray-active-text-color),
      focus-bg-color: var(--fin-button-flat-gray-focus-bg-color),
      focus-border-color: var(--fin-button-flat-gray-focus-border-color),
      focus-text-color: var(--fin-button-flat-gray-focus-text-color),
      disabled-bg-color: var(--fin-button-flat-gray-disabled-bg-color),
      disabled-border-color: null,
      disabled-text-color: var(--fin-button-flat-gray-disabled-text-color),
    )
  );
}

// --------------------------------------
// Dynamic
// --------------------------------------
button[appearance='fin-btn-dynamic'] {
  max-width: 100%;

  [tuiWrapper][data-appearance='fin-btn-dynamic'] {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;

    .t-content {
      display: inline;
      line-height: 44px;
    }

    @include fin-mixins.button-appearance(
      (
        bg-color: var(--fin-button-dynamic-bg-color),
        border-color: null,
        text-color: var(--fin-button-dynamic-text-color),
        hover-bg-color: var(--fin-button-dynamic-hover-bg-color),
        hover-border-color: null,
        hover-text-color: var(--fin-button-dynamic-hover-text-color),
        active-bg-color: var(--fin-button-dynamic-active-bg-color),
        active-border-color: null,
        active-text-color: var(--fin-button-dynamic-active-text-color),
        focus-bg-color: var(--fin-button-dynamic-focus-bg-color),
        focus-border-color: var(--fin-button-dynamic-focus-border-color),
        focus-text-color: var(--fin-button-dynamic-focus-text-color),
        disabled-bg-color: var(--fin-button-dynamic-disabled-bg-color),
        disabled-border-color: null,
        disabled-text-color: var(--fin-button-dynamic-disabled-text-color),
        font-family: var(--fin-button-dynamic-font-family),
      )
    );
  }
}
