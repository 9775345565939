@mixin wrapper-hover {
    @media (hover: hover) {
        &:hover:not(._no-hover),
        &[data-state='hover'] {
            @content;
        }
    }
}

@mixin wrapper-active {
    &:active:not(._no-active),
    &[data-state='active'],
    &[data-state='active']:hover {
        @content;
    }
}

@mixin wrapper-readonly($native: false) {
    // Specificity artificially increased to match `:hover:not()` level
    @if $native == true {
        &:read-only:read-only,
        &[data-state='readonly'][data-state='readonly'] {
            @content;
        }
    }

    @if $native == false {
        &[data-state='readonly'][data-state='readonly'] {
            @content;
        }
    }
}

@mixin wrapper-disabled {
    // Specificity artificially increased to match `:hover:not()` level
    &:disabled:disabled,
    &[data-state='disabled'][data-state='disabled'] {
        @content;
    }
}

@mixin wrapper-focus {
    // TODO: Join rules together once all browsers support focus-visible
    // Specificity artificially increased to match `:hover:not()` level
    &:focus-visible:focus-visible {
        @content;
    }

    &._focused._focused {
        @content;
    }
}

@mixin wrapper-invalid {
    &:invalid:invalid,
    &._invalid._invalid {
        @content;
    }
}
