tui-checkbox-block {
  --fin-checkbox-block-border-color: var(--fin-color-gray-cool-100);

  --fin-checkbox-block-text-color: var(--fin-color-base-black);
  --fin-checkbox-block-bg-color: var(--fin-color-base-white);

  --fin-checkbox-block-hover-bg-color: var(--fin-color-gray-cool-25);
  --fin-checkbox-block-active-bg-color: var(--fin-color-gray-cool-50);
  --fin-checkbox-block-focus-bg-color: var(--fin-color-gray-cool-25);
  --fin-checkbox-block-focus-border-color: var(--fin-color-primary-light-300);
  --fin-checkbox-block-disabled-text-color: var(--fin-color-gray-cool-300);
  --fin-checkbox-block-error-border-color: var(--fin-color-error-500);
}
