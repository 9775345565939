@use '@taiga-ui/core/styles/mixins/wrapper' as wrapper;

tui-checkbox-labeled {
  .t-wrapper .t-content {
    color: var(--fin-checkbox-label-text-color);
  }
}

// --------------------------------------
// Primary Button
// --------------------------------------
[tuiWrapper][data-appearance='fin-checkbox-unselected'] {
  background: var(--fin-checkbox-unselected-bg-color);
  color: var(--fin-checkbox-unselected-text-color);
  border-radius: 4px;

  &:after {
    border: 1px solid var(--fin-checkbox-unselected-border-color);
  }

  @include wrapper.wrapper-hover {
    background: var(--fin-checkbox-unselected-hover-bg-color);
    &:after {
      border-color: var(--fin-checkbox-unselected-hover-border-color);
    }
  }
  @include wrapper.wrapper-active {
    background: var(--fin-checkbox-unselected-active-bg-color);
    &:after {
      border-color: var(--fin-checkbox-unselected-active-border-color);
    }
  }
  @include wrapper.wrapper-focus {
    background: var(--fin-checkbox-unselected-focus-bg-color);
    &:after {
      border: 1px solid var(--fin-checkbox-unselected-focus-border-color);
    }
  }
  @include wrapper.wrapper-disabled {
    background: var(--fin-checkbox-unselected-disabled-bg-color);
    &:after {
      border-color: var(--fin-checkbox-unselected-disabled-border-color);
    }
  }
}

[tuiWrapper][data-appearance='fin-checkbox-selected'] {
  background: var(--fin-checkbox-selected-bg-color);
  color: var(--fin-checkbox-selected-text-color);
  border-radius: 4px;

  &:after {
    border: 1px solid var(--fin-checkbox-selected-border-color);
  }

  @include wrapper.wrapper-hover {
    background: var(--fin-checkbox-selected-hover-bg-color);
    &:after {
      border-color: var(--fin-checkbox-selected-hover-border-color);
    }
  }
  @include wrapper.wrapper-active {
    background: var(--fin-checkbox-selected-active-bg-color);
    &:after {
      border-color: var(--fin-checkbox-selected-active-border-color);
    }
  }
  @include wrapper.wrapper-focus {
    background: var(--fin-checkbox-selected-focus-bg-color);
    &:after {
      border: 1px solid var(--fin-checkbox-selected-focus-border-color);
    }
  }
  @include wrapper.wrapper-disabled {
    background: var(--fin-checkbox-selected-disabled-bg-color);
    &:after {
      border-color: var(--fin-checkbox-selected-disabled-border-color);
    }
  }
}
