fin-data-grid {
  --fin-data-grid-border: 1px solid var(--fin-color-gray-warm-200);
  --fin-data-grid-th-column-text-color: var(--fin-color-base-white);
  --fin-data-grid-th-column-bg-color: var(--fin-color-primary-dark-500);
  --fin-data-grid-tr-selected-bg-color: var(--fin-color-primary-light-25);
  --fin-data-grid-tr-disabled-bg-color: var(--fin-color-gray-warm-50);
  --fin-data-grid-td-column-text-color: var(--fin-color-base-black);
  --fin-data-grid-td-column-disabled-text-color: var(--fin-color-gray-warm-500);
  --fin-data-grid-td-column-loading-indicator-progress-color: var(--fin-color-primary-light-500);
  --fin-data-grid-filters-toggle-bar-bg-color: var(--fin-color-primary-light-500);
  --fin-data-grid-filters-toggle-bar-label-color: var(--fin-color-base-white);
  --fin-data-grid-filters-bar-td-column-bg-color: var(--fin-color-base-white);

  --fin-data-grid-th-column-checkbox-unselected-text-color: var(--fin-color-base-white);
  --fin-data-grid-th-column-checkbox-unselected-bg-color: var(--fin-color-primary-dark-300);
  --fin-data-grid-th-column-checkbox-unselected-border-color: none;
  --fin-data-grid-th-column-checkbox-unselected-hover-bg-color: var(--fin-color-primary-dark-400);
  --fin-data-grid-th-column-checkbox-unselected-hover-border-color: none;
  --fin-data-grid-th-column-checkbox-unselected-active-bg-color: var(--fin-color-primary-dark-600);
  --fin-data-grid-th-column-checkbox-unselected-active-border-color: none;
  --fin-data-grid-th-column-checkbox-unselected-focus-bg-color: var(--fin-color-primary-dark-400);
  --fin-data-grid-th-column-checkbox-unselected-focus-border-color: var(--fin-color-gray-cool-300); // ???
  // --fin-data-grid-th-column-checkbox-unselected-disabled-bg-color: var(--fin-color-gray-cool-25);
  --fin-data-grid-th-column-checkbox-unselected-disabled-border-color: none;

  --fin-data-grid-th-column-checkbox-selected-text-color: var(--fin-color-primary-dark-500);
  --fin-data-grid-th-column-checkbox-selected-bg-color: var(--fin-color-primary-dark-200);
  --fin-data-grid-th-column-checkbox-selected-border-color: none;
  --fin-data-grid-th-column-checkbox-selected-hover-bg-color: var(--fin-color-primary-dark-300);
  --fin-data-grid-th-column-checkbox-selected-hover-border-color: none;
  --fin-data-grid-th-column-checkbox-selected-active-bg-color: var(--fin-color-primary-dark-500);
  --fin-data-grid-th-column-checkbox-selected-active-border-color: none;
  --fin-data-grid-th-column-checkbox-selected-focus-bg-color: var(--fin-color-primary-dark-300);
  --fin-data-grid-th-column-checkbox-selected-focus-border-color: var(--fin-color-gray-cool-300); // ???
  // --fin-data-grid-th-column-checkbox-selected-disabled-bg-color: var(--fin-color-gray-cool-25);
  --fin-data-grid-th-column-checkbox-selected-disabled-border-color: none;
}
