[tuiWrapper][data-appearance='fin-btn-primary'] {
  --fin-button-primary-bg-color: var(--fin-color-primary-light-500);
  --fin-button-primary-text-color: var(--fin-color-base-white);
  --fin-button-primary-hover-bg-color: var(--fin-color-primary-light-400);
  --fin-button-primary-hover-text-color: var(--fin-color-base-white);
  --fin-button-primary-active-bg-color: var(--fin-color-primary-dark-500);
  --fin-button-primary-active-text-color: var(--fin-color-base-white);
  --fin-button-primary-focus-bg-color: var(--fin-color-primary-light-400);
  --fin-button-primary-focus-border-color: var(--fin-color-primary-light-700);
  --fin-button-primary-disabled-bg-color: var(--fin-color-gray-cool-100);
  --fin-button-primary-disabled-text-color: var(--fin-color-gray-cool-300);
}

[tuiWrapper][data-appearance='fin-btn-secondary'] {
  --fin-button-secondary-bg-color: var(--fin-color-primary-light-50);
  --fin-button-secondary-text-color: var(--fin-color-primary-light-500);
  --fin-button-secondary-hover-bg-color: var(--fin-color-primary-light-100);
  --fin-button-secondary-hover-text-color: var(--fin-color-primary-light-500);
  --fin-button-secondary-active-bg-color: var(--fin-color-primary-light-400);
  --fin-button-secondary-active-text-color: var(--fin-color-base-white);
  --fin-button-secondary-focus-bg-color: var(--fin-color-primary-light-100);
  --fin-button-secondary-focus-border-color: var(--fin-color-primary-light-500);
  --fin-button-secondary-focus-text-color: var(--fin-color-primary-light-500);
  --fin-button-secondary-disabled-bg-color: var(--fin-color-gray-cool-100);
  --fin-button-secondary-disabled-text-color: var(--fin-color-gray-cool-300);
}

[tuiWrapper][data-appearance='fin-btn-outline'] {
  --fin-button-outline-bg-color: var(--fin-color-base-white);
  --fin-button-outline-border-color: var(--fin-color-primary-light-500);
  --fin-button-outline-text-color: var(--fin-color-primary-light-500);
  --fin-button-outline-hover-bg-color: var(--fin-color-primary-light-50);
  --fin-button-outline-hover-border-color: var(--fin-color-primary-light-500);
  --fin-button-outline-hover-text-color: var(--fin-color-primary-light-500);
  --fin-button-outline-active-bg-color: var(--fin-color-primary-light-100);
  --fin-button-outline-active-border-color: var(--fin-color-primary-light-700);
  --fin-button-outline-active-text-color: var(--fin-color-primary-light-700);
  --fin-button-outline-focus-bg-color: var(--fin-color-primary-light-100);
  --fin-button-outline-focus-border-color: var(--fin-color-primary-light-500);
  --fin-button-outline-focus-text-color: var(--fin-color-primary-light-500);
  --fin-button-outline-disabled-bg-color: var(--fin-color-gray-cool-100);
  --fin-button-outline-disabled-border-color: var(--fin-color-gray-cool-200);
  --fin-button-outline-disabled-text-color: var(--fin-color-gray-cool-300);
}

[tuiWrapper][data-appearance='fin-btn-flat'] {
  --fin-button-flat-bg-color: var(--fin-color-base-white);
  --fin-button-flat-text-color: var(--fin-color-primary-light-500);
  --fin-button-flat-hover-bg-color: var(--fin-color-primary-light-50);
  --fin-button-flat-hover-text-color: var(--fin-color-primary-light-500);
  --fin-button-flat-active-bg-color: var(--fin-color-primary-light-100);
  --fin-button-flat-active-text-color: var(--fin-color-primary-light-700);
  --fin-button-flat-focus-bg-color: var(--fin-color-primary-light-50);
  --fin-button-flat-focus-border-color: var(--fin-color-primary-light-500);
  --fin-button-flat-focus-text-color: var(--fin-color-primary-light-500);
  --fin-button-flat-disabled-bg-color: var(--fin-color-base-white);
  --fin-button-flat-disabled-text-color: var(--fin-color-gray-cool-300);
}

[tuiWrapper][data-appearance='fin-btn-secondary-gray'] {
  --fin-button-secondary-gray-bg-color: var(--fin-color-gray-warm-50);
  --fin-button-secondary-gray-text-color: var(--fin-color-gray-cool-500);
  --fin-button-secondary-gray-hover-bg-color: var(--fin-color-gray-cool-100);
  --fin-button-secondary-gray-hover-text-color: var(--fin-color-gray-cool-500);
  --fin-button-secondary-gray-active-bg-color: var(--fin-color-gray-cool-200);
  --fin-button-secondary-gray-active-text-color: var(--fin-color-gray-cool-700);
  --fin-button-secondary-gray-focus-bg-color: var(--fin-color-gray-cool-100);
  --fin-button-secondary-gray-focus-border-color: var(--fin-color-gray-cool-500);
  --fin-button-secondary-gray-focus-text-color: var(--fin-color-gray-cool-500);
  --fin-button-secondary-gray-disabled-bg-color: var(--fin-color-gray-cool-100);
  --fin-button-secondary-gray-disabled-text-color: var(--fin-color-gray-cool-300);
}

[tuiWrapper][data-appearance='fin-btn-outline-gray'] {
  --fin-button-outline-gray-bg-color: var(--fin-color-base-white);
  --fin-button-outline-gray-border-color: var(--fin-color-gray-cool-300);
  --fin-button-outline-gray-text-color: var(--fin-color-gray-cool-500);
  --fin-button-outline-gray-hover-bg-color: var(--fin-color-gray-warm-100);
  --fin-button-outline-gray-hover-border-color: var(--fin-color-gray-warm-100);
  --fin-button-outline-gray-hover-text-color: var(--fin-color-gray-cool-500);
  --fin-button-outline-gray-active-bg-color: var(--fin-color-gray-warm-100);
  --fin-button-outline-gray-active-border-color: var(--fin-color-gray-warm-100);
  --fin-button-outline-gray-active-text-color: var(--fin-color-gray-cool-700);
  --fin-button-outline-gray-focus-bg-color: var(--fin-color-gray-warm-100);
  --fin-button-outline-gray-focus-border-color: var(--fin-color-gray-cool-500);
  --fin-button-outline-gray-focus-text-color: var(--fin-color-gray-cool-500);
  --fin-button-outline-gray-disabled-bg-color: var(--fin-color-gray-cool-100);
  --fin-button-outline-gray-disabled-border-color: var(--fin-color-gray-cool-200);
  --fin-button-outline-gray-disabled-text-color: var(--fin-color-gray-cool-300);
}

[tuiWrapper][data-appearance='fin-btn-flat-gray'] {
  --fin-button-flat-gray-bg-color: var(--fin-color-base-white);
  --fin-button-flat-gray-text-color: var(--fin-color-gray-cool-500);
  --fin-button-flat-gray-hover-bg-color: var(--fin-color-gray-warm-100);
  --fin-button-flat-gray-hover-text-color: var(--fin-color-gray-cool-500);
  --fin-button-flat-gray-active-bg-color: var(--fin-color-gray-warm-100);
  --fin-button-flat-gray-active-text-color: var(--fin-color-gray-cool-700);
  --fin-button-flat-gray-focus-bg-color: var(--fin-color-gray-warm-100);
  --fin-button-flat-gray-focus-border-color: var(--fin-color-gray-cool-500);
  --fin-button-flat-gray-focus-text-color: var(--fin-color-gray-cool-500);
  --fin-button-flat-gray-disabled-bg-color: var(--fin-color-gray-cool-100);
  --fin-button-flat-gray-disabled-text-color: var(--fin-color-gray-cool-300);
}

[tuiWrapper][data-appearance='fin-btn-accent'] {
  --fin-button-accent-bg-color: var(--fin-color-error-500);
  --fin-button-accent-text-color: var(--fin-color-base-white);
  --fin-button-accent-hover-bg-color: var(--fin-color-error-400);
  --fin-button-accent-active-bg-color: var(--fin-color-error-600);
  --fin-button-accent-focus-border-color: var(--fin-color-error-700);
  --fin-button-accent-disabled-bg-color: var(--fin-color-error-300);
  --fin-button-accent-disabled-text-color: var(--fin-color-base-white);
}

[tuiWrapper][data-appearance='fin-btn-outline-accent'] {
  --fin-button-outline-accent-bg-color: var(--fin-color-base-white);
  --fin-button-outline-accent-border-color: var(--fin-color-error-500);
  --fin-button-outline-accent-text-color: var(--fin-color-error-500);
  --fin-button-outline-accent-hover-bg-color: var(--fin-color-error-50);
  --fin-button-outline-accent-hover-border-color: var(--fin-color-error-500);
  --fin-button-outline-accent-hover-text-color: var(--fin-color-error-500);
  --fin-button-outline-accent-active-bg-color: var(--fin-color-error-100);
  --fin-button-outline-accent-active-border-color: var(--fin-color-error-700);
  --fin-button-outline-accent-active-text-color: var(--fin-color-error-700);
  --fin-button-outline-accent-focus-bg-color: var(--fin-color-error-100);
  --fin-button-outline-accent-focus-border-color: var(--fin-color-error-500);
  --fin-button-outline-accent-focus-text-color: var(--fin-color-error-500);
  --fin-button-outline-accent-disabled-bg-color: var(--fin-color-gray-cool-100);
  --fin-button-outline-accent-disabled-border-color: var(--fin-color-gray-cool-200);
  --fin-button-outline-accent-disabled-text-color: var(--fin-color-gray-cool-300);
}

[tuiWrapper][data-appearance='fin-icon-btn-accent'] {
  --fin-icon-button-accent-bg-color: var(--fin-color-gray-cool-100);
  --fin-icon-button-accent-icon-color: var(--fin-color-gray-cool-500);
  --fin-icon-button-accent-hover-bg-color: var(--fin-color-error-200);
  --fin-icon-button-accent-hover-icon-color: var(--fin-color-error-500);
  --fin-icon-button-accent-active-bg-color: var(--fin-color-error-300);
  --fin-icon-button-accent-active-icon-color: var(--fin-color-error-700);
  --fin-icon-button-accent-focus-bg-color: var(--fin-color-error-200);
  --fin-icon-button-accent-focus-icon-color: var(--fin-color-error-500);
  --fin-icon-button-accent-focus-border-color: var(--fin-color-error-500);
  --fin-icon-button-accent-disabled-bg-color: var(--fin-color-gray-cool-50);
  --fin-icon-button-accent-disabled-icon-color: var(--fin-color-gray-cool-200);
}

[tuiWrapper][data-appearance='fin-btn-flat-accent'] {
  --fin-button-flat-gray-bg-color: var(--fin-color-base-white);
  --fin-button-flat-gray-text-color: var(--fin-color-error-500);
  --fin-button-flat-gray-hover-bg-color: var(--fin-color-error-50);
  --fin-button-flat-gray-hover-text-color: var(--fin-color-error-500);
  --fin-button-flat-gray-active-bg-color: var(--fin-color-error-100);
  --fin-button-flat-gray-active-text-color: var(--fin-color-error-700);
  --fin-button-flat-gray-focus-bg-color: var(--fin-color-error-50);
  --fin-button-flat-gray-focus-border-color: var(--fin-color-error-500);
  --fin-button-flat-gray-focus-text-color: var(--fin-color-error-500);
  --fin-button-flat-gray-disabled-bg-color: var(--fin-color-base-white);
  --fin-button-flat-gray-disabled-text-color: var(--fin-color-gray-cool-300);
}
