.fin-data-grid-table-pagination {
  padding: 14px 24px;
  border: 1px solid var(--fin-data-grid-pagination-border-color);
  border-top-width: 0;
  border-bottom-left-radius: var(--fin-data-grid-pagination-border-radius);
  border-bottom-right-radius: var(--fin-data-grid-pagination-border-radius);
  background-color: var(--fin-data-grid-pagination-bg-color);

  &._scrollable {
    border-top-width: 1px;
  }

  tui-table-pagination {
    height: 20px;

    .t-pages {
      display: flex;
      gap: 8px;

      color: var(--fin-color-gray-cool-600);
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 153.846% */

      .t-strong {
        color: var(--fin-data-grid-pagination-total-pages-color);
        font-size: 13px;
        font-style: normal;
        font-weight: 800;
        line-height: 20px; /* 153.846% */
      }
    }

    span:nth-child(2) {
      gap: 10px !important;

      color: var(--fin-color-gray-cool-600);
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 153.846% */

      & > .t-strong {
        color: var(--fin-data-grid-pagination-total-items-color);
        font-size: 13px;
        font-style: normal;
        font-weight: 800;
        line-height: 20px; /* 153.846% */
      }
    }

    .tui-table-pagination__lines-per-page-wrapper {
      color: var(--fin-color-gray-cool-600);
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 153.846% */
    }

    span:nth-child(2) {
      display: flex;
      gap: 10px;
    }
  }
}
