@use '@taiga-ui/core/styles/mixins/wrapper' as wrapper;

// Ensure that the parent element has a fixed height or max-height to prevent the content from overflowing
@mixin multiline-truncate(
  $word-break: null,
  $overflow-wrap: null,
  $text-overflow: ellipsis,
  $line-clamp: 2,
  $white-space: normal
) {
  overflow: hidden;
  text-overflow: $text-overflow;
  display: -webkit-box;
  -webkit-line-clamp: $line-clamp;
  -webkit-box-orient: vertical;
  white-space: $white-space;
  word-break: $word-break;
  overflow-wrap: $overflow-wrap;
}

// TODO: TaigaUI impl for wrapper-focus has missing part for this mixin for scss - first part of the rule
@mixin wrapper-focus {
  // Temporary rule until we get hostDirectives with Angular 15
  :focus-visible:not([data-focused='false']) > &,
  [data-focused='true'] > & {
    @content;
  }

  // TODO: Join rules together once all browsers support focus-visible
  // Specificity artificially increased to match `:hover:not()` level
  &:focus-visible:focus-visible {
    @content;
  }

  &._focused._focused {
    @content;
  }
}

@mixin button-appearance($colors-config, $border-config: (border-width: 1px, focus-border-width: 1px)) {
  $bg-color: map-get($colors-config, 'bg-color');
  $border-color: map-get($colors-config, 'border-color');
  $text-color: map-get($colors-config, 'text-color');
  $opacity: map-get($colors-config, 'opacity');

  $hover-bg-color: map-get($colors-config, 'hover-bg-color');
  $hover-border-color: map-get($colors-config, 'hover-border-color');
  $hover-text-color: map-get($colors-config, 'hover-text-color');

  $active-bg-color: map-get($colors-config, 'active-bg-color');
  $active-border-color: map-get($colors-config, 'active-border-color');
  $active-text-color: map-get($colors-config, 'active-text-color');

  $focus-bg-color: map-get($colors-config, 'focus-bg-color');
  $focus-border-color: map-get($colors-config, 'focus-border-color');
  $focus-text-color: map-get($colors-config, 'focus-text-color');

  $disabled-bg-color: map-get($colors-config, 'disabled-bg-color');
  $disabled-border-color: map-get($colors-config, 'disabled-border-color');
  $disabled-text-color: map-get($colors-config, 'disabled-text-color');
  $disabled-opacity: map-get($colors-config, 'disabled-opacity');

  $border-width: map-get($border-config, 'border-width');
  $focus-border-width: map-get($border-config, 'focus-border-width');

  opacity: 1;
  background: $bg-color;
  color: $text-color;
  font-size: 15px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px; /* 160% */

  @if map-get($colors-config, 'font-family') {
    font-family: map-get($colors-config, 'font-family');
  }

  @if $opacity {
    opacity: $opacity;
  }

  @if $border-color {
    &:after {
      border: $border-width solid $border-color;
    }
  }

  @include wrapper.wrapper-hover {
    background: $hover-bg-color;
    color: $hover-text-color;
    @if $hover-border-color {
      &:after {
        border-color: $hover-border-color;
      }
    }
  }
  @include wrapper-focus {
    background: $focus-bg-color;
    color: $focus-text-color;
    @if $focus-border-color {
      &:after {
        border-width: $focus-border-width !important;
        border-color: $focus-border-color;
      }
    }
  }
  @include wrapper.wrapper-active {
    background: $active-bg-color;
    color: $active-text-color;
    @if $active-border-color {
      &:after {
        border-color: $active-border-color;
      }
    }
  }
  @include wrapper.wrapper-disabled {
    opacity: 1;
    background: $disabled-bg-color;
    color: $disabled-text-color;

    @if $disabled-opacity {
      opacity: $disabled-opacity;
    }

    @if $disabled-border-color {
      &:after {
        border-color: $disabled-border-color;
      }
    }
  }
}
