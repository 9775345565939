@use '@taiga-ui/core/styles/mixins/wrapper' as wrapper;
@use 'mixins/mixins' as fin-mixins;

label._control,
label.fin-input-field-label._control {
  gap: 4px;

  .t-label {
    margin-bottom: 0 !important;
  }
}

tui-input,
tui-select,
tui-input-date,
tui-input-date-range,
tui-input-number,
tui-input-tag {
  border-radius: 12px;
}

[tuiWrapper][data-appearance='fin-textfield'] {
  border-radius: 12px;
  color: var(--fin-input-field-text-color);
  background: var(--fin-input-field-bg-color);
  box-shadow: var(--fin-input-field-box-shadow);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */

  label.t-placeholder {
    color: var(--fin-input-field-placeholder-color);
  }

  &._autofilled {
    --tui-selection: var(--fin-color-primary-light-200);
  }

  &:after {
    border: 1px solid var(--fin-input-field-border-color);
  }

  @include wrapper.wrapper-hover {
    color: var(--fin-input-field-hover-text-color);
    background: var(--fin-input-field-hover-bg-color);
    box-shadow: var(--fin-input-field-box-hover-shadow);

    &:after {
      border-color: var(--fin-input-field-hover-border-color);
    }
  }

  &._focused:not(._invalid):after {
    border-width: 2px;
    border-color: var(--fin-input-field-active-border-color);
    outline: none;
  }

  @include wrapper.wrapper-invalid {
    color: var(--fin-input-field-error-text-color);
    background: var(--fin-input-field-error-bg-color);
    box-shadow: var(--fin-input-field-error-box-shadow);

    &:after {
      border-color: var(--fin-input-field-error-border-color);
    }
  }

  @include fin-mixins.wrapper-focus {
    --tui-primary: var(--fin-input-field-active-border-color);
    color: var(--fin-input-field-active-text-color);
    background: var(--fin-input-field-active-bg-color);
    box-shadow: var(--fin-input-field-active-box-shadow);
    outline: none;

    :after {
      border-width: 2px;
      outline: none;
    }
  }

  @include wrapper.wrapper-disabled {
    color: var(--fin-input-field-disabled-text-color);
    background: var(--fin-input-field-disabled-bg-color);
    box-shadow: var(--fin-input-field-disabled-box-shadow);
    opacity: 1;

    &:after {
      border-width: 1px;
      border-color: var(--fin-input-field-disabled-border-color);
    }
  }

  @include wrapper.wrapper-readonly {
    color: var(--fin-input-field-disabled-text-color);
    background: var(--fin-input-field-disabled-bg-color);
    box-shadow: var(--fin-input-field-disabled-box-shadow);
    opacity: 1;

    input {
      cursor: default;
    }

    &:after {
      border-width: 1px;
      border-color: var(--fin-input-field-disabled-border-color);
    }
  }
}
