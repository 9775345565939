.fin-info-dialog {
  tui-marker-icon.info,
  tui-marker-icon.warning {
    border-radius: 8px;
    width: 44px;
    height: 44px;

    tui-svg.t-icon {
      transform: scale(1) !important;
      width: 28px;
      height: 28px;
    }
  }

  tui-marker-icon.info {
    background-color: var(--fin-info-dialog-marker-icon-primary-bg-color);
    color: var(--fin-info-dialog-marker-icon-primary-color);
  }

  tui-marker-icon.warning {
    background-color: var(--fin-info-dialog-marker-icon-warning-bg-color);
    color: var(--fin-info-dialog-marker-icon-warning-color);
  }
}
