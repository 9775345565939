fin-step {
  // Step state 'normal'
  --fin-step-normal-line-bg-color: var(--fin-color-gray-cool-200);
  --fin-step-normal-label-text-color: var(--fin-color-gray-cool-300);
  --fin-step-normal-radio-bg-color: var(--fin-color-base-white);
  --fin-step-normal-radio-border-color: var(--fin-color-gray-cool-200);

  --fin-step-normal-line-hover-bg-color: var(--fin-color-gray-cool-300);
  --fin-step-normal-label-text-hover-color: var(--fin-color-gray-cool-300);
  --fin-step-normal-radio-hover-border-color: var(--fin-color-gray-cool-300);

  --fin-step-normal-line-active-bg-color: var(--fin-color-gray-cool-500);
  --fin-step-normal-label-text-active-color: var(--fin-color-gray-cool-500);
  --fin-step-normal-radio-active-border-color: var(--fin-color-gray-cool-500);

  --fin-step-normal-line-focus-bg-color: var(--fin-color-gray-cool-400);
  --fin-step-normal-label-text-focus-color: var(--fin-color-gray-cool-400);
  --fin-step-normal-radio-focus-border-color: var(--fin-color-gray-cool-400);

  --fin-step-normal-readonly-line-bg-color: var(--fin-color-gray-cool-200);
  --fin-step-normal-readonly-label-text-color: var(--fin-color-gray-cool-300);
  --fin-step-normal-readonly-radio-border-color: var(--fin-color-gray-cool-200);

  // Step state 'active'
  --fin-step-active-line-bg-color: var(--fin-color-primary-light-500);
  --fin-step-active-label-text-color: var(--fin-color-primary-light-500);
  --fin-step-active-radio-bg-color: var(--fin-color-base-white);
  --fin-step-active-radio-border-color: var(--fin-color-primary-light-500);

  --fin-step-active-line-hover-bg-color: var(--fin-color-primary-light-400);
  --fin-step-active-label-text-hover-color: var(--fin-color-primary-light-400);
  --fin-step-active-radio-hover-border-color: var(--fin-color-primary-light-400);

  --fin-step-active-line-active-bg-color: var(--fin-color-primary-dark-500);
  --fin-step-active-label-text-active-color: var(--fin-color-primary-dark-500);
  --fin-step-active-radio-active-border-color: var(--fin-color-primary-dark-500);

  --fin-step-active-line-focus-bg-color: var(--fin-color-primary-light-700);
  --fin-step-active-label-text-focus-color: var(--fin-color-primary-light-700);
  --fin-step-active-radio-focus-border-color: var(--fin-color-primary-light-700);

  --fin-step-active-readonly-line-bg-color: var(--fin-color-primary-light-300);
  --fin-step-active-readonly-label-text-color: var(--fin-color-primary-light-300);
  --fin-step-active-readonly-radio-border-color: var(--fin-color-primary-light-300);

  // Step state 'pass'
  --fin-step-passed-line-bg-color: var(--fin-color-gray-cool-500);
  --fin-step-passed-label-text-color: var(--fin-color-gray-cool-500);
  --fin-step-passed-checkbox-color: var(--fin-color-base-white);
  --fin-step-passed-checkbox-bg-color: var(--fin-color-gray-cool-500);

  --fin-step-passed-line-hover-bg-color: var(--fin-color-gray-cool-300);
  --fin-step-passed-label-text-hover-color: var(--fin-color-gray-cool-500);
  --fin-step-passed-checkbox-hover-bg-color: var(--fin-color-gray-cool-300);

  --fin-step-passed-line-active-bg-color: var(--fin-color-gray-cool-700);
  --fin-step-passed-label-text-active-color: var(--fin-color-gray-cool-700);
  --fin-step-passed-checkbox-active-bg-color: var(--fin-color-gray-cool-700);

  --fin-step-passed-line-focus-bg-color: var(--fin-color-gray-cool-600);
  --fin-step-passed-label-text-focus-color: var(--fin-color-gray-cool-600);
  --fin-step-passed-checkbox-focus-bg-color: var(--fin-color-gray-cool-600);

  --fin-step-passed-readonly-line-bg-color: var(--fin-color-gray-cool-200);
  --fin-step-passed-readonly-label-text-color: var(--fin-color-gray-cool-300);
  --fin-step-passed-readonly-checkbox-bg-color: var(--fin-color-gray-cool-100);
}
