:root {
  /* Typography | Font Family */
  --fin-font-family: 'Manrope', sans-serif;
  --fin-font-feature-settings: normal;
  --fin-font-variation-settings: normal;

  /* Typography | Font Size */

  /* Primary colors */
  /* White and Dark Colors */
  --fin-color-base-white: #ffffff;
  --fin-color-base-black: #303344;

  --fin-color-gray-cool-25: #f9fafa;
  --fin-color-gray-cool-50: #f3f3f4;
  --fin-color-gray-cool-100: #e7e8ea;
  --fin-color-gray-cool-200: #dcdde1;
  --fin-color-gray-cool-300: #afb0ba;
  --fin-color-gray-cool-400: #989aa6;
  --fin-color-gray-cool-500: #8d8f9c; // Default
  --fin-color-gray-cool-600: #6a6d7f;
  --fin-color-gray-cool-700: #606271;
  --fin-color-gray-cool-800: #484c62;
  --fin-color-gray-cool-900: #303344;

  --fin-color-primary-light-25: #f2f8ff;
  --fin-color-primary-light-50: #edf5fd;
  --fin-color-primary-light-100: #dcedfc;
  --fin-color-primary-light-200: #b9dafc;
  --fin-color-primary-light-300: #9eccff;
  --fin-color-primary-light-400: #70b6f6;
  --fin-color-primary-light-500: #4190e6; // Default
  --fin-color-primary-light-600: #1b75d8;
  --fin-color-primary-light-700: #0465cf;
  --fin-color-primary-light-800: #0055b1;
  --fin-color-primary-light-900: #014084;

  --fin-color-primary-dark-25: #f5f8ff;
  --fin-color-primary-dark-50: #eff4ff;
  --fin-color-primary-dark-100: #ccd8e3;
  --fin-color-primary-dark-200: #99b1c6;
  --fin-color-primary-dark-300: #7987a6;
  --fin-color-primary-dark-400: #4c5f89;
  --fin-color-primary-dark-500: #1f376b; // Default
  --fin-color-primary-dark-600: #122b61;
  --fin-color-primary-dark-700: #002544;
  --fin-color-primary-dark-800: #00182d;
  --fin-color-primary-dark-900: #101828;

  /* Secondary colors */
  --fin-color-gray-warm-25: #fafafa;
  --fin-color-gray-warm-50: #f6f6f6;
  --fin-color-gray-warm-100: #ededed;
  --fin-color-gray-warm-200: #e0e0e0;
  --fin-color-gray-warm-300: #b0b0b0;
  --fin-color-gray-warm-400: #959595;
  --fin-color-gray-warm-500: #808080; // Default
  --fin-color-gray-warm-600: #6e6d6d;
  --fin-color-gray-warm-700: #575757;
  --fin-color-gray-warm-800: #333;
  --fin-color-gray-warm-900: #000;

  --fin-color-error-25: #fffbfa;
  --fin-color-error-50: #fef3f2;
  --fin-color-error-100: #fdeae4;
  --fin-color-error-200: #fcd6ca;
  --fin-color-error-300: #fabba7;
  --fin-color-error-400: #ff8c67;
  --fin-color-error-500: #f45725; // Default
  --fin-color-error-600: #d92d20;
  --fin-color-error-700: #b42318;
  --fin-color-error-800: #912018;
  --fin-color-error-900: #7a271a;

  --fin-color-warning-25: #fefbf1;
  --fin-color-warning-50: #fef7e0;
  --fin-color-warning-100: #fef1c1;
  --fin-color-warning-200: #fee797;
  --fin-color-warning-300: #ffc700;
  --fin-color-warning-400: #fdb022;
  --fin-color-warning-500: #ff8a00; // Default
  --fin-color-warning-600: #dc6803;
  --fin-color-warning-700: #b54708;
  --fin-color-warning-800: #93370d;
  --fin-color-warning-900: #7a2e0e;

  --fin-color-success-25: #f5fbf9;
  --fin-color-success-50: #dcfff2;
  --fin-color-success-100: #bdffe7;
  --fin-color-success-200: #8af9d1;
  --fin-color-success-300: #5debb7;
  --fin-color-success-400: #4ac99b;
  --fin-color-success-500: #3aa981; // Default
  --fin-color-success-600: #29966f;
  --fin-color-success-700: #1e8862;
  --fin-color-success-800: #136e4d;
  --fin-color-success-900: #0c5d40;

  --fin-shadow-1: 0px 2px 3px 0px rgba(72, 76, 98, 0.1);
  --fin-shadow-2: 0px 2px 5px 0px rgba(72, 76, 98, 0.16);
  --fin-shadow-3: 0px 4px 24px 0px rgba(72, 76, 98, 0.12);
  --fin-shadow-4: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
  --fin-shadow-5:
    0px 3px 6px 0px rgba(0, 0, 0, 0.05), 0px 11px 11px rgba(0, 0, 0, 0.04), 0px 25px 15px rgba(0, 0, 0, 0.03);
  --fin-shadow-6: 0px 12px 36px 0px rgba(72, 76, 98, 0.2);
  --fin-shadow-7: 0px 12px 36px 0px rgba(0, 0, 0, 0.2);
  --fin-shadow-8: 0px 18px 30px 0px rgba(72, 76, 98, 0.5);
}
