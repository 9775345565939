@use '@taiga-ui/core/styles/mixins/wrapper' as wrapper;

fin-radio-block {
  .tui-group_rounded.tui-group_orientation_horizontal > *:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .tui-group_rounded.tui-group_orientation_horizontal > *:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  tui-radio-block {
    flex-basis: content;

    [tuiWrapper][data-appearance='whiteblock'] {
      background: var(--fin-radio-block-bg-color);
      align-items: center;

      > .t-label > .t-content {
        color: var(--fin-radio-block-text-color);
      }

      &:after {
        border-color: var(--fin-radio-block-border-color);
      }

      @include wrapper.wrapper-hover {
        background: var(--fin-radio-block-hover-bg-color);
      }

      @include wrapper.wrapper-active {
        background: var(--fin-radio-block-active-bg-color);
      }

      @include wrapper.wrapper-focus {
        background: var(--fin-radio-block-focus-bg-color);

        &:after {
          border-color: var(--fin-radio-block-focus-border-color);
        }
      }

      @include wrapper.wrapper-disabled {
        opacity: 1;

        > .t-label > .t-content {
          color: var(--fin-radio-block-disabled-text-color);
        }
      }
    }

    &[data-size='s'] {
      [tuiWrapper][data-appearance='whiteblock'] {
        > tui-radio {
          margin: 8px 10px !important;
        }

        > .t-label {
          min-height: 16px;
          align-self: unset;
        }

        > .t-label > .t-content {
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px; /* 123.077% */
        }
      }
    }

    &[data-size='m'] {
      [tuiWrapper][data-appearance='whiteblock'] {
        > tui-radio {
          margin: 10px 12px !important;
        }

        > .t-label {
          min-height: 24px;
          align-self: unset;
        }

        > .t-label > .t-content {
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }

    &[data-size='l'] {
      [tuiWrapper][data-appearance='whiteblock'] {
        > tui-radio {
          margin: 12px 12px !important;
        }

        > .t-label {
          min-height: 24px;
          align-self: unset;
        }

        > .t-label > .t-content {
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 160% */
        }
      }
    }

    &._readonly {
      [tuiWrapper][data-appearance='whiteblock'] {
        opacity: 1;

        > .t-label > .t-content {
          color: var(--fin-radio-block-disabled-text-color);
        }
      }
    }
  }

  tui-radio._readonly {
    [tuiWrapper][data-appearance='fin-radio-button-unselected'] {
      opacity: 1;
      background: var(--fin-radio-unselected-disabled-bg-color);
      &:after {
        border-color: var(--fin-radio-unselected-disabled-border-color);
      }
    }

    [tuiWrapper][data-appearance='fin-radio-button-selected'] {
      opacity: 1;
      background: var(--fin-radio-selected-disabled-bg-color);
      &:after {
        border-color: var(--fin-radio-selected-disabled-border-color);
      }
    }
  }
}
