:root {
  --tui-font-text: var(--fin-font-family);

  --tui-base-01: var(--fin-color-base-white);
  --tui-base-02: var(--fin-color-gray-cool-50);
  --tui-base-03: var(--fin-color-gray-cool-200);
  --tui-base-04: var(--fin-color-gray-cool-300);
  --tui-base-05: var(--fin-color-gray-cool-400);
  --tui-base-06: var(--fin-color-gray-cool-500);
  --tui-base-07: var(--fin-color-gray-cool-600);
  --tui-base-08: var(--fin-color-gray-cool-700);
  --tui-base-09: var(--fin-color-gray-cool-800);

  --tui-text-01: var(--fin-color-base-black);
  --tui-text-02: var(--fin-color-gray-cool-700);
  --tui-text-03: var(--fin-color-gray-cool-500);

  --tui-link: var(--fin-color-primary-light-500);
  --tui-link-hover: var(--fin-color-primary-light-400);
  --tui-positive: var(--fin-color-success-500);
  --tui-positive-hover: var(--fin-color-success-400);
  --tui-negative: var(--fin-color-error-500);
  --tui-negative-hover: var(--fin-color-error-400);

  --tui-primary: var(--fin-color-primary-light-500);
  --tui-primary-hover: var(--fin-color-primary-light-400);
  --tui-primary-active: var(--fin-color-primary-dark-500);
  --tui-primary-text: var(--fin-color-base-white);

  --tui-secondary: var(--fin-color-primary-light-50);
  --tui-secondary-hover: var(--fin-color-primary-light-100);
  --tui-secondary-active: var(--fin-color-primary-light-200);
  --tui-secondary-text: var(--fin-color-primary-light-500);

  --tui-accent: var(--fin-color-error-500);
  --tui-accent-hover: var(--fin-color-error-400);
  --tui-accent-active: var(--fin-color-error-700);
  --tui-accent-text: var(--fin-color-base-white);

  --tui-selection: var(--fin-color-primary-light-100);

  --tui-focus: var(--fin-color-gray-cool-300);

  --tui-autofill: var(--fin-color-primary-light-50);

  --tui-error-fill: var(--fin-color-error-500);
  --tui-error-bg: var(--fin-color-error-50);
  --tui-error-bg-hover: var(--fin-color-error-400);
  --tui-success-fill: var(--fin-color-success-500);
  --tui-success-bg: var(--fin-color-success-50);
  --tui-success-bg-hover: var(--fin-color-success-400);
  --tui-warning-fill: var(--fin-color-warning-400);
  --tui-warning-bg: var(--fin-color-warning-50);
  --tui-warning-bg-hover: var(--fin-color-warning-200);
  --tui-info-fill: var(--fin-color-primary-light-500);
  --tui-info-bg: var(--fin-color-primary-light-50);
  --tui-info-bg-hover: var(--fin-color-primary-light-400);
  --tui-neutral-fill: var(--fin-color-gray-cool-500);
  --tui-neutral-bg: var(--fin-color-gray-cool-50);
  --tui-neutral-bg-hover: var(--fin-color-gray-cool-300);

  --tui-support-01: var(--fin-color-primary-light-300);
  --tui-support-02: var(--fin-color-primary-light-500);
  --tui-support-03: var(--fin-color-success-300);
  --tui-support-04: var(--fin-color-success-500);
  --tui-support-05: var(--fin-color-error-300);
  --tui-support-06: var(--fin-color-error-500);
  --tui-support-07: var(--fin-color-warning-300);
  --tui-support-08: var(--fin-color-warning-500);
  // --tui-support-09: var(--fin-color-); // Used in Taiga UI documentation only
  // --tui-support-10: var(--fin-color-); // Used in Taiga UI documentation only
  --tui-support-11: var(--fin-color-warning-100);
  --tui-support-12: var(--fin-color-warning-300);
  // --tui-support-13: var(--fin-color-); // Used in Taiga UI documentation only
  // --tui-support-14: var(--fin-color-); // Used in Taiga UI documentation only
  --tui-support-15: var(--fin-color-success-400);
  --tui-support-16: var(--fin-color-success-600);
  // --tui-support-17: var(--fin-color-); // Used in Taiga UI documentation only
  // --tui-support-18: var(--fin-color-); // Used in Taiga UI documentation only
  --tui-support-19: var(--fin-color-success-50);
  --tui-support-20: var(--fin-color-success-200);
  --tui-support-21: var(--fin-color-primary-dark-100);

  // Shadows
  --tui-shadow: var(--fin-shadow-3);
  --tui-shadow-hover: var(--fin-shadow-6);
  --tui-shadow-dropdown: var(--fin-shadow-1);
  --tui-shadow-modal: var(--fin-shadow-8);
  --tui-shadow-sidebar: var(--fin-shadow-3);
  --tui-shadow-navigation: var(--fin-shadow-3);
  --tui-shadow-sheet: var(--fin-shadow-8);

  --tui-elevation-01: var(--fin-color-base-white);
  --tui-elevation-02: var(--fin-color-base-white);
}
